import React from 'react';

class Globals extends React.Component {

    // sample object available globally, just import this file, no need to new it up.
    static userInfo = {
        userName: "paul@obtero.com",
        firstName: "Paul"
    };

    static hccCodes = [];
    static icdCodes = [];

    static snomedToIcd10Map = new Map();
}

export default Globals;




//import Globals from '../Common/globals'


//let userInfo = Globals.userInfo // => { foo: "bar" }

//alert(userInfo.userName + ' ' + userInfo.firstName); // paul

//userInfo.userName = 'greg@obtero.com';
//userInfo.firstName = 'Greg';

//alert(userInfo.userName + ' ' + userInfo.firstName); // greg