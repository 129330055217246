import axios from 'axios';
import { smartUser } from '../Obtero/Utility/SmartApi'

let handlerEnabled = true;

const requestHandler = (request) => {
    if (handlerEnabled) {
        //console.log('Request: ' + request.url + ' ' + JSON.stringify(request.data));
    }
    return request;
};

const errorHandler = (error) => {
    if (handlerEnabled) {
        console.warn('Error: ' + error.response.status + ' ' + error.config.url);
        logError('AxiosApi', JSON.stringify(error));
    }
    return Promise.reject({ ...error });
};

const successHandler = (response) => {
    if (handlerEnabled) {
        //console.log('Success: ' + response.config.url);
    }
    return response;
};


const axiosInstance = axios.create({
    baseURL: '',
    headers: {
        'Access-Control-Allow-Origin': '*'
    }
});

axiosInstance.interceptors.request.use(
    request => requestHandler(request)
);

axiosInstance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
);


const logError = async (methodName, errorDescription) => {

    const user = await smartUser().then((user) => { return user });
    const userId = user.id;

    await axios.post(`/api/utility/LogError`, { userId: userId, methodName: methodName, errorDescription: errorDescription });

};

export default axiosInstance;
