//import axios from '../../Common/axios';
import axios from 'axios';
//import AxiosAPI from '../../Common/axiosAPI';
import Globals from '../../Common/globals';


export const smartUser = () => {

    let promise = new Promise((resolve, reject) => {

        window.FHIR.oauth2.ready((smart) => {

            smart.user.read().then((u) => {
                resolve(u);
            });

        });

    });

    return promise;
}
export const smartPatient = () => {

    let promise = new Promise((resolve, reject) => {

        window.FHIR.oauth2.ready((smart) => {

            smart.patient.read().then((p) => {
                resolve(p);
            });

        });

    });

    return promise;
}

export const smartApiSearch = async (searchParams) => {

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    if (window.smartApiThrottle && window.smartApiThrottle > 0) {
        console.log("Sleep for " + window.smartApiThrottle.toString() + " millisecond " + new Date());
        await sleep(window.smartApiThrottle);
        console.log("Awake!                   " + new Date());
    }



    let promise = new Promise((resolve, reject) => {

        const initializeSmart = async () => {
            //logEvent('SmartApiSearch - initializeSmart');
            await window.FHIR.oauth2.ready(onReady, onError);
        }

        const onReady = async (smart) => {

            //logEvent('SmartApiSearch - onReady');

            if (smart.tokenResponse.sim_error) {
                logError('SmartApiSearch', 'Token expired');
                reject('Token expired');
            }

            await smart.api.search(searchParams).then(async (response) => {

                const r = await response;

                if (searchParams.type === 'Condition') {
                    if (r.data.entry) {
                        await AddIcd10FromSnomed(r.data.entry);
                    }
                }

                resolve(r);

            }).catch((error) => {
                logEvent('SmartApiSearch: ' + error);
                logError('SmartApiSearch', error);
                //reject(error);
                let retval = {};
                retval.data = {};
                retval.data.entry = [];
                resolve(retval);
            });

        }

        const onError = (error) => {
            logError('SmartApiSearch', error);
            reject(error);  // error, rejected
        }

        initializeSmart();

    });

    return promise;

}

const AddIcd10FromSnomed = async (conditions) => {

    //logEvent('AddIcd10FromSnomed: ' + conditions.length);

    for (let i = 0; i < conditions.length; i++) {

        if (Globals.snomedToIcd10Map.has(conditions[i].resource.code.coding[0].code)) {

            let data = Globals.snomedToIcd10Map.get(conditions[i].resource.code.coding[0].code);

            //logEvent('Snomed to ICD-10 (cache): ' + conditions[i].resource.code.coding[0].code + ' : ' + data.icd10Code);

            conditions[i].resource.code.coding.push({
                system: "icd10cm",
                code: data.icd10Code,
                display: data.icd10Description,
                mapsToHCC: data.mapsToHCC,
                hccCode: data.hccCode,
                subChapter: data.subChapter,
                snomedCode: data.snomedCode,
                snomedDescription: data.snomedDescription

            });

            conditions[i].resource.code.coding.push({
                system: "icd9cm",
                code: data.icd9EquivalentCode,
                display: ""
            });

        }
        else {

            await axios.post(`/api/import/SnomedToIcd10`, { SnomedCode: conditions[i].resource.code.coding[0].code })
                .then((result) => {

                    //console.log(conditions[i].resource.code.coding[0].code);
                    //console.log(result.data);

                    Globals.snomedToIcd10Map.set(conditions[i].resource.code.coding[0].code, result.data);

                    //logEvent('Snomed to ICD-10 (api): ' + conditions[i].resource.code.coding[0].code + ' : ' + result.data.icd10Code);

                    conditions[i].resource.code.coding.push({
                        system: "icd10cm",
                        code: result.data.icd10Code,
                        display: result.data.icd10Description,
                        mapsToHCC: result.data.mapsToHCC,
                        hccCode: result.data.hccCode,
                        subChapter: result.data.subChapter,
                        snomedCode: result.data.snomedCode,
                        snomedDescription: result.data.snomedDescription
                    });

                    conditions[i].resource.code.coding.push({
                        system: "icd9cm",
                        code: result.data.icd9EquivalentCode,
                        display: ""
                    });

                }).catch((error) => {

                    conditions[i].resource.code.coding.push({
                        system: "icd10cm",
                        code: "",
                        display: ""
                    });

                    conditions[i].resource.code.coding.push({
                        system: "icd9cm",
                        code: "",
                        display: ""
                    });

                });

        }

    }

    return conditions;

};


const logEvent = (methodName) => {
    console.log('Obtero Event Log: ' + JSON.stringify(methodName));
};


const logError = async (methodName, errorDescription) => {

    const user = await smartUser().then((user) => { return user });
    const userId = user.id;

    await axios.post(`/api/utility/LogError`, { userId: userId, methodName: methodName, errorDescription: errorDescription});

};


