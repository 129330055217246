import 'react-app-polyfill/ie9';
import 'core-js/es/string';
import 'core-js/es/array';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/object';
import 'core-js/es/promise';
//import 'core-js/es7/object';
//import 'core-js/es7/array';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';

import './i18n';

import configureStore from './store/store';
const store = configureStore();

// this is done to allow browser session storage time to
// store the smart authorization response from index.html.
setTimeout(
    function () {
        ReactDOM.render(
            <Provider store={store}>
                <App />
            </Provider>,
            document.getElementById('app')
        )
    },
    500
);
